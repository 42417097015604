import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { get } from "lodash";

import { ContentBlock, TextBlock } from "../../components/Blocks";
import Contents from "../../components/Contents";
import Pages from "../../components/Pages";
import { PureCarousel } from "../../components/PureReactCarousel";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import { makeUrl, removePreloader } from "../../helpers";
import { Share } from "../../widgets";
import EmptyData from "../../components/EmptyData";
import { HOLIDAY } from "../../queries/queries.graphql";
import withPageData from "../../containers/withPageData";

import "./holiday.scss";

export const query = graphql`
	query HolidayQuery($slug: String!) {
		hasura {
			...Holiday
		}
	}
`;

function Holidays({ data, pageData }) {
	const hasuraData = get(data, "hasura", {});
	const { loading: dataLoading, error: dataError } = pageData;

	const [holiday, setHoliday] = useState(get(hasuraData, "holidays[0]", {}));

	useEffect(() => {
		if (!dataError) {
			setHoliday(get(pageData, "holidays[0]", {}));
		}

		removePreloader(!dataLoading || dataError);
	}, [dataLoading]);

	const title = get(holiday, "page_title_full", "");
	const images = get(holiday, "images", []);
	const content_blocks = get(holiday, "content_blocks", []);

	const imageSrc = get(holiday, "images[0].src", "");
	const url = makeUrl.holiday(holiday);

	const crumbItem = {
		item: {
			title_full: "Праздники",
			slug: "",
		},
	};

	return holiday ? (
		<Pages entity={holiday} url={url}>
			<ContentBlock key={"Breadcrumbs"}>
				<div className="container">
					<Breadcrumbs
						currentLabel={title}
						pageContext={crumbItem}
					/>
				</div>
			</ContentBlock>
			<ContentBlock key={"media-content"}>
				<div className={"container"}>
					<h1 className={"h1"} itemProp="headline">{title}</h1>
					<TextBlock className={"d-flex flex-column"}>
						<PureCarousel
							type={"SlideImage"}
							items={images}
							mediaSize={["100%", "auto"]}
							className={"holiday-carousel"}
							params={
								{
									infinite: true,
									popup: true,
									slidesPerView: 4,
									breakpoints: [
										{
											width: 0,
											slidesPerView: 1,
										},
										{
											width: 768,
											slidesPerView: 3,
										},
									],
								}
							}
							slideRatio={{
								naturalSlideWidth: 3,
								naturalSlideHeight: 4,
							}}
						/>
					</TextBlock>
					<Contents items={content_blocks} />
				</div>
			</ContentBlock>
			<Share url={url} pageTitleFull={title} imageSrc={imageSrc} />
		</Pages>
	) : (
		<EmptyData />
	);
}

export default withPageData(
	Holidays,
	{ body: HOLIDAY,
		name: "pageData" },
);

Holidays.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object,
	pageData: PropTypes.object,
};

Holidays.defaultProps = {
	data: {},
	pageContext: {
		slug: "",
	},
	pageData: {},
};

